<template>
  <Dialog :name="name" :width="tableWidth">
    <template #title>
      <div>Машины на парковке</div>
    </template>
    <SupervisorTableBody
      :table-data="statisticList"
      :table-fields-desktop="tableColumns"
    >
      <template #default="slotProps">
        <SupervisorTableCell
          :row="slotProps.data.row"
          :prop-key="slotProps.data.prop"
        />
      </template>
    </SupervisorTableBody>
  </Dialog>
</template>

<script>
import { GET_IS_MOBILE, GET_SUPERVISOR_STATISTIC_TABLE } from '@/store/actions'
import { SUPERVISOR_PARKING_INFO } from '@/constants/dialogs'
import { mapGetters } from 'vuex'
import {
  supervisorTableFields,
  supervisorTableFieldsMobile,
} from '@/views/supervisor/data/supervisorData'
import Dialog from '@/UI/dialog/Dialog'
import SupervisorTableBody from '@/views/supervisor/components/supervisor-statistics/components/SupervisorTableBody.vue'
import SupervisorTableCell from '@/views/supervisor/components/supervisor-statistics/components/SupervisorTableCell.vue'
export default {
  name: 'SupervisorParkingInfo',
  components: { SupervisorTableCell, SupervisorTableBody, Dialog },
  data() {
    return {
      name: SUPERVISOR_PARKING_INFO,
    }
  },
  computed: {
    ...mapGetters({
      statisticList: GET_SUPERVISOR_STATISTIC_TABLE,
      isMobile: GET_IS_MOBILE,
    }),
    tableColumns() {
      return this.isMobile ? supervisorTableFieldsMobile : supervisorTableFields
    },
    tableWidth() {
      return this.isMobile ? '94%' : '1015px'
    },
  },
}
</script>
