var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      attrs: { name: _vm.name, width: _vm.tableWidth },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_c("div", [_vm._v("Машины на парковке")])]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("SupervisorTableBody", {
        attrs: {
          "table-data": _vm.statisticList,
          "table-fields-desktop": _vm.tableColumns,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (slotProps) {
              return [
                _c("SupervisorTableCell", {
                  attrs: {
                    row: slotProps.data.row,
                    "prop-key": slotProps.data.prop,
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }